@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter-UI";
  src: url("./assets/fonts/Inter-UI.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("./assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-Semibold";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./assets/fonts/Montserrat-Bold.ttf");
}

*:focus {
  @apply outline-none;
}

/* :root {
  --primary-color: @apply bg-purple-700;
  --primary-hover-color: @apply bg-purple-500;
  --primary-active-color: @apply bg-purple-900;
} */

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @apply text-slate-800;
  @apply font-primaryRegular;
  @apply bg-gray-200;
}

h1,h2,h3,h4,h5,h6,b {
  /* @apply font-title; */
  @apply font-primaryRegular;
  @apply font-bold;
  @apply text-slate-900;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title-bold {
  @apply font-secondaryTitle
}

.title-semibold {
  @apply font-secondarySemibold
}

.title-regular {
  @apply font-secondaryRegular
}
